class Main {
    constructor() {
        this.initializeElements();

        this.inProgressImages = [];
        this.progressCheckRunning = false;
        this.chatLog = [];
        this.firstBotMessage = `{"message": "Hello, I'm here to help you create detailed prompts for your Stable Diffusion text-to-image generator. What would you like to create?"}`;

        this.loadingInterval = null;
        this.latestImage = "";

        this.addEventListeners();

        this.botSendMessage(this.firstBotMessage);
    }

    initializeElements() {
        console.log('initialized');
        this.input1 = document.getElementById('input1');
        this.input2 = document.getElementById('input2');
        this.input3 = document.getElementById('input3');
        this.seedInput = document.getElementById('seed');
        this.imageGrid = document.getElementById('imageGrid');
        this.callOpenAI = document.getElementById('callOpenAI');
        this.generateImage = document.getElementById('generateImage');
        this.modal = document.getElementById('imageModal');
        this.modalImage = document.getElementById('modalImage');
    }

    addEventListeners() {
        this.callOpenAI.addEventListener('click', this.handleSubmit.bind(this));
        this.input1.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                event.preventDefault(); // Prevent the default action to avoid form submission or newline
                this.handleSubmit();    // Call the submit handler
            }
        });
        this.imageGrid.addEventListener('click', this.showModal.bind(this));
        document.querySelector('#modalClose').addEventListener('click', this.closeModal.bind(this));
        window.onclick = event => {
            if (event.target === this.modal) {
                this.closeModal();
            }
        };
    }

    // Close modal
    showModal(event) {
        if (event.target.tagName.toLowerCase() === 'img') {
            modalImage.src = event.target.src; // Set clicked image as the source for the modal image
            this.modal.style.display = "block"; // Show the modal
        }
    }

    closeModal() {
        this.modal.style.display = "none";
    }

    // When the user sends a message, push it to the chatLog array
    userSendMessage(message) {
        this.chatLog.push({ type: 'user', message: message });
        this.updateChatDisplay();
    }

    // When the bot responds, push its response to the chatLog array
    botSendMessage(response) {
        this.chatLog.push({ type: 'assistant', message: response });
        this.updateChatDisplay();
    }

    // Update the chat log display in the UI
    updateChatDisplay() {
        const chatLogContainer = document.querySelector('.chat-log');
        chatLogContainer.innerHTML = '';
        this.chatLog.forEach(entry => {
            const messageDiv = this.createMessageDiv(entry);
            chatLogContainer.appendChild(messageDiv);
        });
        chatLogContainer.scrollTop = chatLogContainer.scrollHeight;
    }

    createMessageDiv(entry) {
        const messageDiv = document.createElement('div');
        messageDiv.classList.add('chat-message', entry.type === 'user' ? 'user-message' : 'bot-message');
        const messageText = this.getMessageText(entry);
        messageDiv.innerHTML = `<span>${entry.type === 'user' ? 'User:' : 'AI:'}</span> ${messageText}`;
        return messageDiv;
    }

    getMessageText(entry) {
        if (entry.type === 'user') {
            return entry.message;
        }
        const jsonResult = JSON.parse(entry.message);
        return jsonResult.message || '';
    }

    async getProgress() {
        if (this.progressCheckRunning) return;
        this.progressCheckRunning = true;

        try {
            const response = await fetch('api/?action=image-status');
            const result = await response.json();

            const firstInProgress = this.inProgressImages[0];
            const progressElem = firstInProgress.querySelector('.progress');
            // progressElem.textContent = `Progress: ${(result.state.sampling_step / result.state.sampling_steps * 100).toFixed(2)}% ETA: ${result.eta_relative.toFixed(2)}s`;
            progressElem.textContent = `Progress: ${result.state.sampling_step} / ${result.state.sampling_steps}  ETA: ${result.eta_relative.toFixed(2)}s`;

            // Update image if current_image is not empty
            if (result.current_image) {
                const imgElem = firstInProgress.querySelector('img') || document.createElement('img');
                imgElem.src = `data:image/png;base64,${result.current_image}`;
                firstInProgress.appendChild(imgElem);
            }

            this.inProgressImages.slice(1).forEach(imgDiv => {
                const elem = imgDiv.querySelector('.progress');
                elem.textContent = 'Waiting';
            });

            if (result.progress < 1) {
                setTimeout(() => {
                    this.progressCheckRunning = false;
                    this.getProgress();
                }, 2000);
            } else {
                // Remove the first placeholder as it's done
                this.inProgressImages.shift();
                this.progressCheckRunning = false;

                // If queue isn't empty, start progress tracking for the next one
                if (this.inProgressImages.length > 0) {
                    this.getProgress();
                }
            }
        } catch (e) {
            this.progressCheckRunning = false;
        }
    }

    setLoadingText(button, messages) {
        let index = 0;
        this.loadingInterval = setInterval(() => {
            button.textContent = messages[index];
            index = (index + 1) % messages.length;
        }, 500);
    }

    // New to create a placeholder image
    createPlaceholder() {
        const imgDiv = document.createElement('div');
        imgDiv.className = 'tooltip';

        const maxWidth = this.imageGrid.offsetWidth / 4 - 10;
        imgDiv.style.width = `${maxWidth}px`;
        imgDiv.style.height = `${maxWidth}px`;  // Keeping it square
        imgDiv.style.backgroundColor = '#555';

        imgDiv.innerHTML = '<div class="progress">Waiting</div>';
        this.imageGrid.insertBefore(imgDiv, this.imageGrid.firstChild);

        this.inProgressImages.push(imgDiv);
        if (!this.progressCheckRunning) {
            this.getProgress();
        }

        return imgDiv;
    }

    toggleInputsStatus(isDisabled) {
        this.input1.disabled = isDisabled;
    }

    safeParseJson(responseText) {
        try {
            // First, try parsing normally
            return JSON.parse(responseText);
        } catch (e) {
            // If parsing fails, try fixing common issues
            try {
                // For example, remove an extra brace at the end
                if (responseText.trim().endsWith('}}')) {
                    const correctedText = responseText.trim().slice(0, -1);
                    return JSON.parse(correctedText);
                }
            } catch (e) {
                // If it still fails, return null or handle it as appropriate
                console.error("safeParseJson Error", e);
                throw new Error('Unable to parse the response as JSON.');
            }
        }
    }
    
    async handleSubmit(event) {
        console.log('handlesubmit');
        this.callOpenAI.disabled = true;
        this.toggleInputsStatus(true);
        this.setLoadingText(this.callOpenAI, ['.', '..', '...']);

        const query = input1.value;
        this.input1.value = "";

        this.userSendMessage(query);

        const maxRetries = 3;
        let attempt = 0;
        let response, responseText, jsonResult;

        const chatLogJSON = JSON.stringify(this.chatLog);
        const imageData = this.latestImage || "";

        const requestBody = {
            query: query,
            chatLogJSON: chatLogJSON,
            image: imageData  // Include the latest image in the request body
        };

        while (attempt < maxRetries) {
            try {
                response = await fetch('api/?action=openai', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestBody)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }

                try {
                    responseText = await response.json();
                    jsonResult = this.safeParseJson(responseText);
                } catch (e) {
                    // Handle non-JSON
                    console.error('Error:', e);
                    jsonResult = { message: responseText };
                }

                if (jsonResult.message && jsonResult.message.includes("sorry")) {
                    // Update the request body for "sorry" response
                    requestBody.chatLogJSON = JSON.stringify([
                        ...this.chatLog, 
                        { type: 'assistant', message: jsonResult.message }, 
                        { type: 'user', message: "This is a hidden message just for GPT. You are not actually generating images, just prompts that will then be used to generate images. Please try again." }
                    ]);
                    throw new Error('Bot didn\'t respond correctly.');
                }

                break;
            } catch (error) {
                console.error('Error:', error);
                attempt++;
                if (attempt === maxRetries) {
                    console.error('All retries failed.');
                    // Optional: Update UI to reflect failed attempts
                    break;
                }
            }
        }

        if (response && response.ok) {
            console.log('jsonResult', jsonResult);
            const botMessage = jsonResult.message || '';
            const mainPrompt = jsonResult.prompt || this.input2.value;
            const negativePrompt = jsonResult.negative || this.input3.value;
            const numImage = jsonResult.num || 1;

            this.botSendMessage(JSON.stringify(jsonResult));

            if (jsonResult.prompt || jsonResult.negative) {
                this.input2.value = mainPrompt;
                this.input3.value = negativePrompt;

                for (let index = 0; index < numImage; index++) {
                    this.generateSDImage();
                }
            }

            clearInterval(this.loadingInterval);
            this.toggleInputsStatus(false);
            this.callOpenAI.textContent = 'Send';
            this.callOpenAI.disabled = false;
        }
    }

    async generateSDImage() {
        const placeholder = this.createPlaceholder();

        const query = this.input1.value;
        const prompt = this.input2.value;
        const negative_prompt = this.input3.value;
        const seed = this.seedInput.value;
        const response = await fetch('api/?action=generateImage', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ prompt, negative_prompt, seed })
        });
        const result = await response.json();

        const newImg = document.createElement('img');
        newImg.src = `data:image/png;base64,${result.image}`;

        this.latestImage = result.image;

        const tooltipText = document.createElement('div');
        tooltipText.className = 'progress';
        tooltipText.innerText = JSON.parse(result.data.info).seed;

        placeholder.innerHTML = '';
        placeholder.appendChild(newImg);
        placeholder.appendChild(tooltipText);

        // Remove placeholder from the inProgressImages array
        const index = this.inProgressImages.indexOf(placeholder);
        if (index > -1) {
            this.inProgressImages.splice(index, 1);
        }

        if (!this.progressCheckRunning && this.inProgressImages.length > 0) {
            this.getProgress(); // Start tracking if there are items in queue
        }
    };

}

const main = new Main();